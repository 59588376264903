import { render, staticRenderFns } from "./UploadData.vue?vue&type=template&id=a63de976&scoped=true"
import script from "./UploadData.vue?vue&type=script&lang=js"
export * from "./UploadData.vue?vue&type=script&lang=js"
import style0 from "./UploadData.vue?vue&type=style&index=0&id=a63de976&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a63de976",
  null
  
)

export default component.exports