<template>
  <div>
    <!-- 上传朝阳校保Excel文件 -->
    <el-form ref="form" :model="form" label-width="120px">

      <el-form-item label="上传退费表">
        <el-upload
            class="upload-excel"
            ref="uploadTuifei"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeTuifei"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件(.xlsx,.xls)</div>
        </el-upload>
      </el-form-item>

      <!-- 上传组长津贴Excel文件 -->
      <el-form-item label="上传组长津贴Excel文件">
        <el-upload
            class="upload-excel"
            ref="uploadGroupAllowance"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeGroupAllowance"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件(.xlsx,.xls)</div>
        </el-upload>
      </el-form-item>

      <!-- 新增：上传考勤表Excel文件 -->
      <el-form-item label="上传考勤表">
        <el-upload
            class="upload-excel"
            ref="uploadAttendance"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeAttendance"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件(.xlsx,.xls)</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="上传校保">
        <el-upload
            class="upload-excel"
            ref="uploadChaoyangXiaobao"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeChaoyangXiaobao"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件(.xlsx,.xls)</div>
        </el-upload>
      </el-form-item>

      <!-- 上传退费表Excel文件 -->


      <!-- 上传银行流水Excel文件 -->
      <el-form-item label="上传银行流">
        <el-upload
            class="upload-excel"
            ref="uploadBankFlow"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeBankFlow"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件(.xlsx,.xls)</div>
        </el-upload>
      </el-form-item>


      <!-- 选择日期 -->
      <el-form-item label="选择日期">
        <el-date-picker
            v-model="form.date"
            type="month"
            placeholder="选择年月"
            format="yyyy-MM"
            value-format="yyyy-MM"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="submitTuifeiForm">提交退费表文件</el-button>

        <el-button type="success" @click="submitGroupAllowanceForm">提交组长津贴文件</el-button>

        <el-button type="success" @click="submitAttendanceForm">提交考勤表文件</el-button>

        <el-button type="success" @click="submitChaoyangXiaobaoForm">提交朝阳校保文件</el-button>

        <el-button type="success" @click="submitBankFlowForm">提交银行流水文件</el-button>

        <!-- 新增：提交考勤表文件按钮 -->
        <el-button type="warning" @click="refreshData">刷新</el-button>

      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        chaoyangXiaobaoFile: null,
        tuifeiFile: null,
        bankFlowFile: null,
        groupAllowanceFile: null,
        attendanceFile: null, // 新增：用于存储考勤表文件
        date: null
      },
    };
  },
  methods: {
    refreshData() {
      this.$axios({
        method: 'get',
        url: '/Chao/refresh',
      })
          .then((response) => {
            console.log(response.data);
            this.$message.success('刷新成功');
          })
          .catch((error) => {
            console.error('刷新失败:', error);
            this.$message.error('刷新失败');
          });
    },
    handleFileChangeChaoyangXiaobao(file) {
      this.form.chaoyangXiaobaoFile = file.raw;
    },
    handleFileChangeTuifei(file) {
      this.form.tuifeiFile = file.raw;
    },
    handleFileChangeBankFlow(file) {
      this.form.bankFlowFile = file.raw;
    },
    handleFileChangeGroupAllowance(file) {
      this.form.groupAllowanceFile = file.raw;
    },
    // 新增：处理考勤表文件上传变化
    handleFileChangeAttendance(file) {
      this.form.attendanceFile = file.raw;
    },

    submitChaoyangXiaobaoForm() {
      if (!this.form.chaoyangXiaobaoFile ||!this.form.date) {
        this.$message.error('请确保朝阳校保文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.chaoyangXiaobaoFile);
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/Chao/UploadXiaobao', // 替换为实际的上传接口地址
        data: formData,
        responseType: 'blob' // 重要，处理返回的文件流
      })
          .then((response) => {
            // 创建一个可下载的链接
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'response.xlsx'); // 可以自定义下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // 释放掉blob对象使用的内存
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
    submitTuifeiForm() {
      if (!this.form.tuifeiFile ||!this.form.date) {
        this.$message.error('请确保退费表文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.tuifeiFile);
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/Chao/UploadRefund', // 替换为实际的上传接口地址
        data: formData,
      })
          .then((response) => {
            console.log(response.data);
            this.$message.success('退费表文件上传成功');
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
    submitBankFlowForm() {
      if (!this.form.bankFlowFile ||!this.form.date) {
        this.$message.error('请确保银行流水文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.bankFlowFile);    //这行代码创建了一个新的 FormData 对象。FormData 是 JavaScript 中的一个内置对象，它提供了一种方便的方式来构造和处理表单数据。可以把它想象成一个容器，能够容纳各种类型的数据，例如文本、文件等，并且这些数据可以按照类似于表单提交的格式组织起来，方便在网络请求中发送给服务器。
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/Chao/UploadBank', // 替换为实际的上传接口地址
        data: formData,
      })
          .then((response) => {
            console.log(response.data);
            this.$message.success('银行流水文件上传成功');
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
    submitGroupAllowanceForm() {
      if (!this.form.groupAllowanceFile ||!this.form.date) {
        this.$message.error('请确保组长津贴文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.groupAllowanceFile);
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/Chao/UploadTeacherLeaderBonus', // 替换为实际的上传接口地址
        data: formData,
      })
          .then((response) => {
            console.log(response.data);
            this.$message.success('组长津贴文件上传成功');
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
    // 新增：提交考勤表文件的方法
    submitAttendanceForm() {
      if (!this.form.attendanceFile ||!this.form.date) {
        this.$message.error('请确保考勤表文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.attendanceFile);
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/Chao/UploadKaoqin', // 替换为实际的上传接口地址
        data: formData,
      })
          .then((response) => {
            console.log(response.data);
            this.$message.success('考勤表文件上传成功');
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
  },
};
</script>

<style scoped>
.upload-excel.el-upload {
  display: block;
}
</style>