<template>
  <div>
    <el-button type="primary" @click="handleAdd">添加员工</el-button>
    <el-table

        :data="tableData"
        style="width: 100%"
    >
      <!-- 列定义 -->
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="dept" label="部门" width="180"></el-table-column>
      <el-table-column prop="basicSalary" label="基本工资"></el-table-column>
      <el-table-column prop="kaoqinSalary" label="考勤工资"></el-table-column>
      <el-table-column prop="meritBonus" label="绩效奖金"></el-table-column>
      <el-table-column prop="trafficBonus" label="交通补助"></el-table-column>
      <el-table-column prop="multiposBonus" label="多岗津贴"></el-table-column>
      <el-table-column prop="ageBonus" label="工龄工资"></el-table-column>
      <el-table-column prop="totalSalary" label="总工资"></el-table-column>
      <el-table-column prop="fentan" label="分摊"></el-table-column>
      <el-table-column prop="adjust" label="调整"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑信息" :visible.sync="dialogVisible" width="30%">
      <el-form :model="currentRow">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="currentRow.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门" :label-width="formLabelWidth">
          <el-input v-model="currentRow.dept" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="基本工资" :label-width="formLabelWidth">
          <el-input v-model="currentRow.basicSalary" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="考勤工资" :label-width="formLabelWidth">
          <el-input v-model="currentRow.kaoqinSalary" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="绩效奖金" :label-width="formLabelWidth">
          <el-input v-model="currentRow.meritBonus" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="交通补助" :label-width="formLabelWidth">
          <el-input v-model="currentRow.trafficBonus" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="多岗津贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.multiposBonus" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="工龄工资" :label-width="formLabelWidth">
          <el-input v-model="currentRow.ageBonus" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="总工资" :label-width="formLabelWidth">
          <el-input v-model="currentRow.totalSalary" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="分摊" :label-width="formLabelWidth">
          <el-input v-model="currentRow.fentan" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="调整" :label-width="formLabelWidth">
          <el-input v-model="currentRow.adjust" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {  //就代表一些全局变量
    return {
      tableData: [],
      currentRow: {},
      dialogVisible: false,
      formLabelWidth: '120px',
      // 新增标识变量，用于区分是编辑还是添加操作
      isEditing: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios.get('/vocation/GetProfile')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error('获取数据失败:', error);
            this.$message.error('获取数据失败，请检查网络或链接合法性');
          });
    },
    handleAdd() {
      this.currentRow = {}; // 清空当前行数据  this.currentRow代表
      this.dialogVisible = true;
      // 设置为添加操作
      this.isEditing = false;
    },
    handleEdit(row) {
      this.currentRow = {...row }; //表示展示当前行的行数据
      this.dialogVisible = true;
      // 设置为编辑操作
      this.isEditing = true;
    },
    handleDelete(row) {
      this.$axios.get(`/vocation/DeleteProfile?name=${encodeURIComponent(row.name)}`)
          .then(() => {
            this.$message.success("删除成功")
            const index = this.tableData.indexOf(row);
            if (index!== -1) {
              this.tableData.splice(index, 1);
            }
          })
          .catch(error => {
            console.error('删除失败:', error);
            this.$message.error('删除失败，请检查网络或链接合法性');
          });
    },
    handleSave() {
      if (this.isEditing) {
        // 编辑操作，发送更新请求
        this.$axios.post('/vocation/UpdateProfile', this.currentRow)  //这里的currentRow代表对话框中的内容
            .then(() => {
              this.$message.success("更新成功");
              const index = this.tableData.findIndex(item => item.name === this.currentRow.name);
              if (index!== -1) {
                this.tableData.splice(index, 1, {...this.currentRow });
              }
              this.dialogVisible = false;
            })
            .catch(error => {
              console.error('更新失败:', error);
              this.$message.error('更新失败，请检查网络或链接合法性');
            });
      } else {
        // 添加操作，发送添加请求
        this.$axios.post('/vocation/AddProfile', this.currentRow)
            .then(() => {
              this.$message.success("添加成功");
              this.tableData.push(this.currentRow);
              this.dialogVisible = false;
            })
            .catch(error => {
              console.error('添加失败:', error);
              this.$message.error('添加失败，请检查网络或链接合法性');
            });
      }
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>