<template>
  <div>
    <el-button type="primary" @click="handleAdd">添加员工入职日期</el-button>
    <el-table
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="date" label="入职日期" width="180"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑入职日期" :visible.sync="dialogVisible" width="30%">
      <el-form :model="currentRow">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="currentRow.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="入职日期" :label-width="formLabelWidth">
          <el-date-picker v-model="currentRow.date" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentRow: {},
      dialogVisible: false,
      formLabelWidth: '120px',
      isEditing: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios.get('/Chao/getAllEntryDates')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error('获取数据失败:', error);
            this.$message.error('获取数据失败，请检查网络或链接合法性');
          });
    },
    handleAdd() {
      this.currentRow = { name: '', date: '' };
      this.dialogVisible = true;
      this.isEditing = false;
    },
    handleEdit(row) {
      this.currentRow = {...row };  //复制行对象
      this.dialogVisible = true;
      this.isEditing=this;

    },
    handleDelete(row) {
      const deleteName = row.name;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(`/Chao/deleteHireDate?name=${deleteName}`)
            .then(() => {
              this.$message.success("删除成功");
              const index = this.tableData.findIndex(item => item.name === row.name);
              if (index!== -1) {
                this.tableData.splice(index, 1);
              }
            })
            .catch(error => {
              console.error('删除失败:', error);
              this.$message.error('删除失败，请检查网络或链接合法性');
            });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSave() {
      if (this.isEditing) {
        const updateData = {
          name: this.currentRow.name,
          date: this.currentRow.date,
        };
        this.$axios.post('/Chao/updateHireDate', updateData)
            .then(() => {
              this.$message.success("更新成功");
              this.fetchData();
            })
            .catch(error => {
              console.error('更新失败:', error);
              this.$message.error('更新失败，请检查网络或链接合法性');
            });
      } else {
        this.$axios.post('/Chao/addHireDate', this.currentRow)
            .then(() => {
              this.$message.success("添加成功");
              this.fetchData();
            })
            .catch(error => {
              console.error('添加失败:', error);
              this.$message.error('添加失败，请检查网络或链接合法性');
            });
      }
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>