<template>
  <div class="button-container">
    <div class="button-row">
      <button @click="getSellerSalary">得到销售单节课课消</button>
    </div>
    <div class="button-row">
      <button @click="getTotalTable">点击教师总表</button>
      <el-date-picker
          v-model="form.date"
          type="month"
          placeholder="选择年月"
          format="yyyy-MM"
          value-format="yyyy-MM"
      ></el-date-picker>
    </div>
    <div class="button-row">
      <button @click="getFrontDeskSalaryByZone">点击得到各个校区前台工资</button>
      <el-date-picker
          v-model="form.date"
          type="month"
          placeholder="选择年月"
          format="yyyy-MM"
          value-format="yyyy-MM"
      ></el-date-picker>
      <select v-model="selectedCampus" @change="getFrontDeskSalaryByZone">
        <option disabled value="">请选择校区</option>
        <option v-for="campus in campuses" :key="campus">{{ campus }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCampus: '',
      campuses: ["北门口校区", "东风路总校", "启迪校区", "文林校区", "实验校区", "玉泉路校区", "市中心校区"],
      form: {
        date: null
      }
    };
  },
  methods: {
    async get7CampusTeacherSalaryByZone() {
      if (!this.date) {
        this.$message.error('请选择年月');
        return;
      }
      try {
        const response = await this.$axios({
          method: 'get',
          url: `/Chao/GetTeacherSalary?date=${this.form.date}`,
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'campusQiantaiSalary.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    },
    async getTotalTable() {
      if (!this.form.date) {
        this.$message.error('请选择年月');
        return;
      }
      try {
        const response = await this.$axios({
          method: 'get',
          url: `/Chao/GetTeacherTotalTable?date=${this.form.date}`,
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'teacherSum.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    },
    async getFrontDeskSalaryByZone() {
      if (!this.selectedCampus || !this.form.date) {
        this.$message.error('请选择校区和年月');
        return;
      }
      try {
        const response = await this.$axios({
          method: 'get',
          url: `/Chao/GetFrontDeskSalaryByCampus?campus=${this.selectedCampus}&date=${this.form.date}`,
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.selectedCampus}_front_desk_salary.xlsx`);
        document.body.appendChild(
            link
        );
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    },
    async getSellerSalary() {
      
      try {
        const response = await this.$axios({
          method: 'get',
          url: `/Chao/GetSellerSalary`,
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'seller_salary.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    }
  }
};
</script>

<style scoped>
.button-container {
  width: 100%;
}

.button-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.button-row button,
.button-row input,
.button-row select {
  margin-right: 10px;
}
</style>