import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';

// 创建axios实例
const instance = axios.create({
    baseURL: 'http://121.199.22.113:80/api'
});

// axios的实例，请求拦截器，用于在每次请求前，接收2个函数参数，一个成功函数，一个失败函数
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['token'] = token;
        }

        // 检查是否存在后端设置的未登录相关响应头，如果有，说明当前可能处于未登录状态，进行相应处理
        const notLoginHeader = config.headers['X-Not-Login'];
        if (notLoginHeader === 'NOT_LOGIN') {
            // 这里发现后端设置了未登录状态头，直接跳转到登录页面
            router.push('/login');
            return Promise.reject('未登录状态，已跳转到登录页面');
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器，用于处理请求返回的错误情况
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                // 这里假设后端对于账号密码错误或者token无效等未登录情况统一返回401
                const currentRoute = router.currentRoute;
                if (currentRoute.path!== '/login') {
                    router.push('/login');
                }
            } else if (error.response.status === 404) {
                Vue.prototype.$message.error('请求的资源不存在');
            } else {
                Vue.prototype.$message.error('请求发生错误，请稍后再试');
            }
        } else {
            Vue.prototype.$message.error('网络连接错误，请检查网络设置');
        }
        return Promise.reject(error);
    }
);

// 将配置好的axios实例挂载到Vue原型上，以便在组件中通过this.$axios访问
Vue.prototype.$axios = instance;

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');