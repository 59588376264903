<template>
  <div>
    <!-- 添加按钮 -->
    <el-button type="primary" @click="handleAdd">添加人员</el-button>

    <!-- 数据表格展示 -->
    <el-table
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="name" label="姓名" width="120"></el-table-column>
      <el-table-column prop="department" label="部门" width="120"></el-table-column>
      <el-table-column prop="ratio" label="比例"></el-table-column>
      <el-table-column prop="basicSalary" label="基本工资"></el-table-column>
      <el-table-column prop="trafficAllowance" label="交通补贴"></el-table-column>
      <el-table-column prop="fullAttendance" label="满勤奖"></el-table-column>
      <el-table-column prop="staticAllowance" label="固定津贴"></el-table-column>
      <el-table-column prop="dynamicAllowance" label="动态津贴"></el-table-column>
      <el-table-column prop="manualInput" label="手动输入"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑对话框 -->
    <el-dialog title="编辑人员信息" :visible.sync="dialogVisible" width="30%">
      <el-form :model="currentRow">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="currentRow.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门" :label-width="formLabelWidth">
          <el-input v-model="currentRow.department" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="比例" :label-width="formLabelWidth">
          <el-input v-model="currentRow.ratio" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="基本工资" :label-width="formLabelWidth">
          <el-input v-model="currentRow.basicSalary" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="交通补贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.trafficAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="满勤奖" :label-width="formLabelWidth">
          <el-input v-model="currentRow.fullAttendance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="固定津贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.staticAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="动态津贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.dynamicAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="手动输入" :label-width="formLabelWidth">
          <el-input v-model="currentRow.manualInput" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  data() {
    return {
      tableData: [], // 用于存储从后端获取的数据
      currentRow: {}, // 当前正在操作的行数据（用于编辑、添加等）
      dialogVisible: false, // 编辑对话框的显示状态
      formLabelWidth: "120px",
      // 新增标识变量，用于区分是编辑还是添加操作
      isEditing: false  //增加一个方法间的
    };
  },
  created() {
    this.fetchData(); // 在组件创建时获取数据
  },
  methods: {
    // 获取数据的方法
    fetchData() {
      this.$axios.get('/Chao/getAllFrontDeskInfo')
          // .get("http://localhost:30002/api/Chao/getAllFrontDeskInfo")
          .then((response) => {
            this.tableData = response.data;
          })
          .catch((error) => {
            console.error("获取数据失败:", error);
            this.$message.error("获取数据失败，请检查网络或链接合法性");
          });
    },
    // 处理添加按钮点击事件
    handleAdd() {
      this.currentRow = {}; // 清空当前行数据，准备添加新数据
      this.dialogVisible = true; // 显示编辑对话框
      this.isEditing=false;
    },
    // 处理行点击事件，用于编辑
    // handleRowClick(row) {
    //   this.currentRow = {...row };
    //   this.dialogVisible = true;
    // },
    // 处理编辑按钮点击事件
    handleEdit(row) {
      this.currentRow = {...row };
      this.dialogVisible = true;
      this.isEditing=true;

    },
    // 处理删除按钮点击事件
    handleDelete(row) {
      this.$axios.get(`/Chao/DeleteQiantaiProfile?name=${encodeURIComponent(row.name)}`)
          .then(() => {
            this.$message.success("删除成功");
            const index = this.tableData.indexOf(row);
            if (index!== -1) {
              this.tableData.splice(index, 1);
            }
          })
          .catch((error) => {
            console.error("删除失败:", error);
            this.$message.error("删除失败，请检查网络或链接合法性");
          });
    },
    // 处理保存按钮点击事件（编辑或添加）
    handleSave() {  //可能是新增确定,编辑确定

        if (this.isEditing) {
          this.$axios.post('/Chao/updateFrontDeskInfo',this.currentRow)
              // .post("http://localhost:30002/api/Chao/updateFrontDeskInfo", this.currentRow)
              .then(() => {
                this.$message.success("更新成功");
                this.fetchData();
              })
              .catch((error) => {
                console.error("更新失败:", error);
                this.$message.error("更新失败，请检查网络或链接合法性");
              });
        } else {
          this.$axios
              .post("/Chao/addFrontDeskInfo", this.currentRow)
              .then(() => {
                this.$message.success("添加成功");
                this.fetchData();
              })
              .catch((error) => {
                console.error("添加失败:", error);
                this.$message.error("添加失败，请检查网络或链接合法性");
              });
        }

      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>