<template>
  <div>
    <el-button type="primary" @click="handleAdd">添加教师</el-button>
    <el-table
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="department" label="部门" width="180"></el-table-column>
      <el-table-column prop="trafficAllowance" label="交通补助"></el-table-column>
      <el-table-column prop="fullAttendance" label="全勤奖"></el-table-column>
      <el-table-column prop="staticAllowance" label="固定津贴"></el-table-column>
      <el-table-column prop="dynamicAllowance" label="绩效津贴"></el-table-column>
      <el-table-column prop="manualInput" label="手动输入"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑教师信息" :visible.sync="dialogVisible" width="30%">
      <el-form :model="currentRow">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="currentRow.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门" :label-width="formLabelWidth">
          <el-input v-model="currentRow.department" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="交通补助" :label-width="formLabelWidth">
          <el-input v-model="currentRow.trafficAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="全勤奖" :label-width="formLabelWidth">
          <el-input v-model="currentRow.fullAttendance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="固定津贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.staticAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="绩效津贴" :label-width="formLabelWidth">
          <el-input v-model="currentRow.dynamicAllowance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="手动输入" :label-width="formLabelWidth">
          <el-input v-model="currentRow.manualInput" autocomplete="off" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentRow: {},
      dialogVisible: false,
      formLabelWidth: '120px',
      isEditing: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios.get('/Chao/getAllTeachers')
          .then(response => {
            this.tableData = response.data;
          })
          .catch(error => {
            console.error('获取数据失败:', error);
            this.$message.error('获取数据失败，请检查网络或链接合法性');
          });
    },
    handleAdd() {
      this.currentRow = {}; // 清空当前行数据
      this.dialogVisible = true; // 显示对话框
      this.isEditing = false;
    },
    handleEdit(row) {
      this.currentRow = { ...row };
      this.dialogVisible = true;
      this.isEditing=true;
    },
    handleDelete(row) {
      this.$axios.get(`/Chao/deleteTeacher?name=${encodeURIComponent(row.name)}`)
          .then(() => {
            this.$message.success("删除成功");
            const index = this.tableData.indexOf(row);
            if (index !== -1) {
              this.tableData.splice(index, 1);
            }
          })
          .catch(error => {
            console.error('删除失败:', error);
            this.$message.error('删除失败，请检查网络或链接合法性');
          });
    },
    handleSave() {
      if (this.isEditing) {
        this.$axios.post('/Chao/updateTeacher', this.currentRow)
            .then(() => {
              this.$message.success("更新成功");
              this.updateTableData();
            })
            .catch(error => {
              console.error('更新失败:', error);
              this.$message.error('更新失败，请检查网络或链接合法性');
            });
      } else {
        this.$axios.post('/Chao/addTeacher', this.currentRow)
            .then(() => {
              this.$message.success("添加成功");
              this.updateTableData();
            })
            .catch(error => {
              console.error('添加失败:', error);
              this.$message.error('添加失败，请检查网络或链接合法性');
            });
      }
      this.dialogVisible = false;
    },
    updateTableData() {
      this.fetchData();
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>