<template>
  <div id="app">
    <el-container v-if="isAuthenticated" style="height: 700px;border:1px solid #eee">
      <el-header style="font-size:40px;background-color:rgb(238, 241, 246)">咸阳朝阳教育</el-header>
      <el-container>
        <el-aside width="230px" style="border: 1px solid #eee">
          <el-menu :default-open="['1', '3']">
            <el-submenu index="1">
              <template slot="title"><i class="el-icon-message"></i>高职模块</template>
              <el-menu-item-group>
                <el-menu-item index="1-1">
                  <router-link to="/GetResult">得到计算结果</router-link>
                </el-menu-item>
                <el-menu-item index="1-2">
                  <router-link to="/UploadData">上传excel数据</router-link>
                </el-menu-item>
                <el-menu-item index="1-3">
                  <router-link to="/profile">修改合同信息</router-link>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- "7校区模块"侧边栏 -->
            <el-submenu index="2">
              <template slot="title"><i class="el-icon-location"></i>7校区模块</template>
              <el-menu-item-group>
                <el-menu-item index="2-1">
                  <router-link to="/src/views/chao/ChaoUpload.vue">上传数据</router-link>
                </el-menu-item>
                <el-menu-item index="2-2">
                  <router-link to="/src/views/chao/ChaoGetResult.vue">得到计算结果</router-link>
                </el-menu-item>
<!--                <el-menu-item index="2-3">-->
<!--                  <router-link to="/src/views/chao/ChaoProfile.vue">修改合同资料</router-link>-->
<!--                </el-menu-item>-->
                <!-- 新增的三个按钮 -->
                <el-menu-item index="2-3">
                  <router-link to="/src/views/chao/ChaoModifyFrontDeskInfo.vue">修改前台信息</router-link>
                </el-menu-item>
                <el-menu-item index="2-4">
                  <router-link to="/src/views/chao/ChaoModifyTeacherInfo.vue">修改教师信息</router-link>
                </el-menu-item>
                <el-menu-item index="2-5">
                  <router-link to="/src/views/chao/ChaoModifyEntryDate.vue">修改入职日期</router-link>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <router-view v-else></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
    };
  },
  watch: {
    $route(to, from) {
      // 只有在路由变化且可能影响认证状态时才更新
      if (to.path === '/login' || from.path === '/login') {
        this.updateAuthStatus(to);
      }
    },
  },
  methods: {
    updateAuthStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        this.isAuthenticated = true;
      } else {
        // 可以在这里添加一些提示信息，比如在控制台输出或者通过UI提示用户未登录等
        console.log('未获取到有效的token，用户未认证');
        this.isAuthenticated = false;
      }
    },
  },
  created() {
    // 组件创建时先获取token并设置初始认证状态
    const token = localStorage.getItem('token');
    if (token) {
      this.isAuthenticated = true;
    }
  },
};
</script>

<style>

</style>